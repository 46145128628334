.itemInfo {
  display: flex;
  gap: 16px;
}

.infoInnerItem {
  display: flex;
  gap: 4px;
  align-items: center;
}

.titleWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}