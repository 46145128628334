.bcWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--secondary-40);
}

.backIcon {
  cursor: pointer;
}

.brItem {
  font-size: 12px;
  line-height: 140%;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.separator {
  margin-left: 8px;
  display: none;
}

.visible {
  display: block;
}