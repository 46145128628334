.table :global .ant-table-thead .ant-table-cell {
  background-color: var(--primary-15);
  color: var(--secondary-50);
  font-weight: bold;
}

.table :global .ant-table-thead .ant-table-cell:before {
  display: none;
}

.table :global .ant-table-container table > thead > tr:first-child > *:first-child {
  border-start-start-radius: 20px;
}

.table :global .ant-table-container table > thead > tr:first-child > *:last-child {
  border-start-end-radius: 20px;
}

.table :global .ant-table-container table > tbody > tr:last-child:hover > td:first-child {
  border-bottom-left-radius: 20px;
}

.table :global .ant-table-container table > tbody > tr:last-child:hover > td:last-child {
  border-bottom-right-radius: 20px;
}

.table :global .ant-table {
  border-radius: 20px;
}

.table :global .ant-table-tbody>tr>td {
  color: var(--secondary-50) !important;
}

.table :global .ant-table-thead th.ant-table-column-has-sorters:hover {
  background-color: var(--primary-15);
}

.table :global .ant-table-thead th.ant-table-column-sort {
  background-color: var(--primary-15);
}

.table :global .ant-table-column-sorters {
  justify-content: normal;
}

.table :global .ant-table-column-title {
  flex: unset;
}

.table :global .ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: none !important;
}

.table :global .ant-table-column-sorter-up {
  color: var(--secondary-50);
}

.table :global .ant-table-column-sorter-down {
  color: var(--secondary-50);
}

.table :global .ant-table-column-sorter-up.active {
  color: var(--secondary-40);
}

.table :global .ant-table-column-sorter-down.active {
  color: var(--secondary-40);
}

.table :global .ant-checkbox-wrapper.ant-checkbox-inner {
  background-color: var(--secondary-0);
  border-color: var(--primary-30);
}
.table :global .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled).ant-checkbox-checked:not(.ant-checkbox-disabled).ant-checkbox-inner  {
  background-color: var(--primary-30) !important;
  border-color: var(--primary-30);
}

.table :global .ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: var(--secondary-0) !important;
  border-color: var(--primary-30) !important;
}

.table :global .ant-checkbox-checked:after {
  border-color: var(--primary-30) !important;
}

.table :global .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: var(--primary-30) !important;
  border-color: var(--primary-30) !important;
}

.table :global .ant-checkbox:not(.ant-checkbox-disabled).ant-checkbox-checked .ant-checkbox-inner {
  border-color: var(--primary-30) !important;
  background-color: var(--primary-30) !important;
}

.table :global .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: var(--primary-30) !important;
}

.table :global .ant-table-row:hover {
  background-color: var(--primary-5) !important;
}

.table :global .ant-table-tbody >tr.ant-table-row:hover>td {
  background-color: inherit !important;
}
