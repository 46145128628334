.firstInput {
  padding: 0 0 8px;
}

.schoolName {
  padding: 16px 0 8px;
}

.uploadWrapper {
  margin-top: 16px;
  display: flex;
  gap: 16px;
}

.logoPreview {
  border: 1px solid var(--secondary-20);
  border-radius: 8px;
}

.upload {
  flex: 1;
}

.logoUpload :global .ant-upload-list {
  display: none;
}

.logoUpload :global .ant-upload-drag {
  border: 1px solid var(--secondary-20);
}
