.singleStudent {
  height: calc(100vh - 64px);
}

.scrollView {
  box-shadow: 0 4px 12px 0 #0000001F;
  background-color: var(--secondary-0);
  z-index: 1;
  position: relative;
}

.breadcrumbWrapper {
  padding: 20px 22px 0;
}

.titleWrapper {
  padding: 16px 0 24px;
}

.descWrapper {
  padding: 0 24px 0 24px;
  height: calc(100vh - 222px);
  overflow-y: auto;
}

.descWrapperScroll {
  padding: 0 24px 0 24px;
  height: calc(100vh - 206px);
  overflow-y: auto;
}

.statusWrapper {
  display: flex;
  gap: 8px;
  margin-bottom: 30px;
}