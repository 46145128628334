.groupTextWrapper {
  padding: 8px 0 8px 0;
}

.groupSummaryWrapper {
  padding: 0 0 8px 0;
}

.editorWrapper {
    margin-top: 24px;
    height: 400px;
}

.editorWrapper > div {
    height: 100%;
}

.customEditor > div:last-child {
    height: calc( 100% - 54px );
}

.editorCount {
    margin-top: 16px;
    text-align: right;
}

.error {
    color: var(--error);
}