.spinWrapper {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableWrapper {
  box-shadow: 0 4px 8px 0 #00000033;
  border-radius: 9px;
  padding: 0 0 10px 0;
  background-color: var(--secondary-0);
}

.tableInner {
  height: 500px;
  overflow-y: auto;
}

.tableInnerNoScroll {
  overflow-y: hidden;
  height: 500px;
}

.tableHeader {
  background-color: var(--primary-10);
  border-radius: 9px 9px 0 0;
  padding: 16px;
  border-bottom: 1px solid var(--secondary-20);
}

.tableRow {
  padding: 16px;
  background-color: var(--secondary-0);
  border-bottom: 1px solid var(--secondary-20);
}

.tableRow:last-child {
  border-bottom: none;
  border-radius: 0 0 20px 20px;
}

.showMore {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}