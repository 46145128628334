@import 'colors.module.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.primary-40 {
  color: var(--primary-40);
}

.secondary-30 {
  color: var(--secondary-30);
}

.secondary-40 {
  color: var(--secondary-40);
}

.errorMessage {
  color: #ff4d4f;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--secondary-30);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-40);
}

.ql-container {
  height: 92% !important;
}

.disabled-row {
  opacity: 0.5
}

.ant-notification-notice {
  background-color: #F6FFED;
  border: 1px solid #52C41A;
  color: #52C41A;
  padding: 9px 16px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-notification-notice-content {
  display: flex;
  align-items: center;
}

.ant-notification-notice-close {
  position: relative !important;
  top: 0!important;
}

.ant-notification-notice-message {
  margin-bottom: 0 !important;
}

.notifyError {
  background-color: #FFE2E0;
  border: 1px solid #FA1C1C;
  color: #FA1C1C;
}

.ant-btn-primary:disabled {
  background-color: #8E8E8E !important;
  color: white;
}

.ant-btn-primary:disabled:hover {
  background-color: #8E8E8E !important;
}
