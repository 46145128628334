.editWrapper {
  padding: 24px;
  text-align: right;
}

.contentWrapper {
  display: flex;
  gap: 24px;
  padding: 24px 24px 0;
}

.logoWrapper {
  width: 144px;
  height: 144px;
  object-fit: cover;
  background-color: var(--secondary-0);
  border-radius: 16px;
  border: 1px solid var(--secondary-30);
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoWrapper {
  flex: 1;
  border-radius: 20px;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
  background-color: var(--secondary-0);
}

.singleInfo {
  display: flex;
  gap: 20px;
  padding: 17px 16px;
  box-shadow: 0 1px 0 #E0E0E0;
}

.singleInfo:last-child {
  box-shadow: none;
}

.singleInfo > p:nth-child(1) {
  width: 30%;
}

