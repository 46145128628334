.reportContainer {
  height: calc(100vh - 64px);
}

.scrollViewWrapper {
  padding: 0 36px 24px 0;
}

.wrapperScrolled {
  box-shadow: 0 4px 12px 0 #0000001F;
  background-color: var(--secondary-0);
  z-index: 1;
  position: relative;
}

.breadcrumbWrapper {
  padding: 20px 22px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statusWrapper {
  display: flex;
  gap: 8px;
}

.titleWrapper {
  padding: 16px 24px 0;
}

.subTitleWrapper {
  padding: 8px 24px 0;
}

.descWrapper {
  padding: 0 24px 0 24px;
  height: calc(100vh - 268px);
  overflow-y: auto;
}

.iconPosition {
  text-align: center;
  padding: 64px 0 32px 0;
}

.placeHolderWrapper {
  text-align: center;
}

.placeHolderText {
  font-weight: 500;
}