.nameLabel {
  margin-bottom: 8px;
}

.addStudentWrapper :global .ant-modal-body  {
  height: calc(100vh - 320px);
  overflow-y: auto;
}

.surNameLabel {
  margin: 16px 0 8px;
}

.rootModal {
  display: none !important;
}
