.headerWrapper {
  padding: 6px 24px;
  background-color: var(--secondary-0);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 10;
}

.userInfo {
  display: flex;
  gap: 12px;
  align-items: center;
  font-weight: bold;
}

.avatarIcon {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: var(--primary-5);
  justify-content: center;
  text-align: center;
  display: flex;
  border: 1px solid var(--secondary-50);
}

.avatarIcon :global .anticon {
  font-size: 24px;
}

.logoutIcon {
  font-size: 24px;
  cursor: pointer;
}

.confirmModal :global .ant-modal-body {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: transparent;
  border: none;
}

.confirmModal :global .ant-modal-footer {
  box-shadow: none;
}

.selectWrapper {
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
}

.dropdownContent :global .ant-dropdown-menu-item {
  width: 100%;
}

.dropdownContent :global .ant-dropdown-menu-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dropdownContent :global .ant-dropdown-menu-title-content > span {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.dropdownContent :global .ant-dropdown-menu-item:hover {
  background-color: var(--primary-5) !important;
}

.dropdownContent :global .ant-dropdown-menu-item > span > span {
  width: calc(100% - 32px);
  display: block;
}
