.containerWrapper {
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
}

.headerWrapper {
  border-radius: 20px 20px 0 0;
  padding: 16px;
  background-color: var(--primary-10);
}

.infoWrapper {
  background-color: var(--secondary-0);
  padding: 16px;
  border-top: 1px solid var(--secondary-20);
}

.infoWrapperLast {
  border-top: 1px solid var(--secondary-20);
  border-radius: 0 0 20px 20px;
  background-color: var(--secondary-0);
  padding: 16px;
}

.consentWrapper {
  padding: 16px;
  background-color: var(--primary-10);
}