.observationList {
  margin-bottom: 20px;
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
}

.observationList > span {
  transform: rotate(180deg);
}

.editorWrapper {
  height: calc(100vh - 420px);
    position: relative;
}

.editorWrapper > div {
  height: 100%
}

.customEditor > div:last-child {
    height: calc( 100% - 54px )
}

.customEditor > div:last-child > div {
    height: 100%;
}
.editorCount {
    text-align: right;
}

.error {
    color: var(--error);
}
