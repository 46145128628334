.personalReview {
  margin-bottom: 20px;
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
}

.personalReview > span {
  transform: rotate(180deg);
}

.collapseWrapper {
  margin: 16px 0;
  background-color: transparent;
  border-radius: 20px;
  border: none;
}

.descCollapse {
  background-color: var(--secondary-0);
}

.collapseWrapper :global .ant-collapse-header {
  border-radius: 20px !important;
}

.collapseWrapper :global .ant-collapse-item {
  border-radius: 20px;
  background-color: var(--primary-10);
  margin: 12px 0;
  box-shadow: 0 4px 8px 0 #00000033;
}

.collapseWrapper :global .ant-collapse-item:last-child {
  border-radius: 20px;
}

.collapseWrapper :global .ant-collapse-content {
  border-radius: 0 0 20px 20px !important;
}

.collapseWrapper :global .ant-collapse-item {
  border: none;
}

.collapseWrapper :global .ant-collapse-content-box {
  padding: 1px 0 !important;
}

.collapseWrapper :global .ant-list-empty-text {
  padding: 0;
  text-align: left;
}

.innerCollapse :global .ant-collapse-content-box {
  padding: 0  !important;
}

.innerCollapse :global .ant-collapse-item {
  background-color: var(--secondary-0) !important;
  margin: 0 !important;
  box-shadow: none;
}

.innerCollapse :global .ant-collapse-item:last-child {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.innerCollapse :global .ant-collapse-item  :global .ant-collapse-header {
  border-radius: 0 !important;
}

.innerCollapse :global .ant-collapse-item:last-child :global .ant-collapse-header {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.innerCollapse :global .ant-collapse-item-active:last-child :global .ant-collapse-header {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.innerCollapse :global .ant-collapse-item-active:last-child :global .ant-collapse-header {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.openReviewBottomBorder {
  padding: 17px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.innerCollapse :global .ant-collapse-item-active:last-child :global .ant-collapse-content-active :global .ant-collapse-content-box :global .ant-row:last-child {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.notFilledCollapse :global .ant-collapse-header {
  background-color: var(--tertiary-2);
  border-radius: 0 !important;
}

.notFilledCollapse :global .ant-collapse-header:last-child {
  background-color: var(--tertiary-2);
}

.compareNotFilled :global .ant-collapse-header {
  background-color: var(--tertiary-3);
  border-radius: 0 !important;
}

.compareNotFilled :global .ant-collapse-header:last-child {
  background-color: var(--tertiary-3);
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.innerCollapse :global .ant-collapse-header {
  padding: 12px 30px !important;
}

.radioGroup :global .ant-radio-wrapper {
  width: 25%;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
}

.collapseOutline {
  border: 1px solid var(--tertiary-1) !important;
}

.outlineCompare {
  border: 1px solid var(--tertiary-4) !important;
}

.subjectSummary {
  display: flex;
  padding: 12px 30px;
  cursor: pointer;
  gap: 8px;
  align-items: center;
}

.disabledSummary {
  color: var(--secondary-30)
}

.plusWrapper {
  padding-top: 5px;
}

.notFilledSubItem {
  background-color: var(--tertiary-2);
}

.compareNotFilledSubItem {
  background-color: var(--tertiary-3);
}

.openReviewMargin {
  margin-top: 8px;
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
}

.openReviewMargin > span {
  transform: rotate(180deg);
}

.iconFontSize {
  font-size: 20px;
}

.paginationWrapper {
  padding: 0 24px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
}

.titleWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.removeEval {
  margin: 0 60px 0 40px;
  padding: 5px 10px;
  cursor: pointer;
  color: var(--secondary-40);
}

.removeEval:hover {
  color: var(--primary-40)
}

.notActive {
  margin: 0 60px 0 40px;
  padding: 5px 10px;
  color: var(--secondary-40);
}