.popoverInner {
  max-height: 260px;
  overflow-y: auto;
  padding: 4px;
}

.filtersWrapper {
    margin-top: 8px;
}

.filterWrapper {
  display: flex;
  align-items: center;
  border: 1px solid var(--primary-40);
  background-color: var(--primary-10);
  border-radius: 4px;
  font-weight: bold;
  gap: 8px;
  font-size: 14px;
  cursor: pointer;
}

.filterWrapperClosed {
  display: flex;
  align-items: center;
  border: 1px solid var(--secondary-30);
  background-color: var(--primary-0);
  border-radius: 4px;
  font-weight: bold;
  gap: 8px;
  font-size: 14px;
  cursor: pointer;
}

.arrow {
  margin-top: -1px;
  padding-right: 12px;
}

.dropdownContent :global .ant-dropdown-menu-item {
  width: 100%;
}

.dropdownContent :global .ant-dropdown-menu-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: 2px 0 !important;
}

.dropdownContent :global .ant-dropdown-menu-title-content > span {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.dropdownContent :global .ant-dropdown-menu-item:hover {
  background-color: var(--primary-5) !important;
}

.dropdownContent :global .ant-dropdown-menu-item > span > span {
  width: calc(100% - 32px);
  display: block;
}

.removeIcon {
  cursor: pointer;
  padding: 4px 0 4px 12px;
}

.popoverHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.popoverHeader :global .ant-btn {
 padding: 0 8px;
  height: 30px !important;
}

.schoolYearSelect {
  display: none !important;
}

.checkboxes {
  display: flex;
  flex-direction: column;
}

.checkboxes :global .ant-checkbox-wrapper {
  margin: 2px 0 !important;
}