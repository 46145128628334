.button {
  color: var(--primary-40) !important;
  border-color: var(--secondary-40) !important;
}

.text {
  color: var(--primary-40) !important;
  font-weight: bold;
}

.text:hover {
  background-color: transparent !important;
  font-weight: bold;
}

.primary {
  box-shadow: none !important;
}

.button:disabled {
  background-color: initial !important;
  border: 1px solid var(--secondary-30);
  color: var(--secondary-30) !important;
}