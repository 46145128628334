.teachersWrapper {
  height: calc(100vh - 64px);
  overflow-y: auto;
}

.titleWrapper {
  padding: 24px 24px 0;
}

.checkboxWrapper {
  margin-bottom: 25px;
}

.dashboardWrapper {
  padding: 0 24px;
  margin: 24px 0;
}

.tableWrapper {
  padding: 0 24px;
}

.paginationWrapper {
  padding: 0 24px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
}

.roleChips {
  padding: 2px 12px;
  background-color: #F9F0FF;
  color: #531DAB;
  border-radius: 20px;
  font-weight: bold;
  margin: 0 2px;
}

.teacherChips {
  padding: 2px 12px;
  background-color: #E6F7FF;
  color: #096DD9;
  border-radius: 20px;
  font-weight: bold;
  margin: 0 2px;
}

.dropdownContent :global .ant-dropdown-menu-item {
  width: 100%;
}

.dropdownContent :global .ant-dropdown-menu-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dropdownContent :global .ant-dropdown-menu-title-content > span {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.dropdownContent :global .ant-dropdown-menu-item:hover {
  background-color: var(--primary-5) !important;
}

.dropdownContent :global .ant-dropdown-menu-item > span > span {
  width: calc(100% - 32px);
  display: block;
}

.teachersTable :global .ant-table-tbody > tr > td:hover {
  color: var(--primary-40) !important;
  cursor: pointer;
  pointer-events: auto;
}