.schoolInfoContainer {
  height: calc(100vh - 64px);
  overflow-y: auto;
}

.titleWrapper {
  padding: 32px 24px 0;
}

.tabsContainer {
  padding: 24px;
}