.menu {
  border: none;
  color: var(--secondary-50);
  background-color: transparent;
  border-inline-end: 0 !important;
}

.menu :global .ant-menu-item-selected {
  color: var(--primary-50);
  background-color: var(--primary-15);
  font-weight: 700;
}

.menu :global .ant-menu-item {
  display: flex;
  align-items: center;
}

