.sidebarIcons {
  font-size: 20px !important;
  color: inherit;
  min-width: 40px !important;
}

.layoutWrapper {
  height: calc(100vh - 64px);
}

.menuWrapper {
  margin-top: 24px;
}

.linksWrapper {
  width: 280px;
  padding: 0 20px 0 40px;
}

.linksWrapperPopup {
  width: 220px;
}

.linksWrapper > div > a {
  color: var(--secondary-50);
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}

.linksWrapper > div > a:hover {
  color: var(--primary-40);
  font-weight: bold;
}

.layout {
  background-color: var(--secondary-7);
}

.sidebar {
  background: var(--secondary-10) !important;
  z-index: 9;
  position: relative;
}

.sidebar :global .ant-layout-sider-trigger {
  background: var(--secondary-10) !important;
}

.sidebar :global .ant-layout-sider-children {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.homeMenu {
  margin: 0 20px;
}

.homeMenu :global .ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: var(--primary-15) !important;
}

.homeMenu :global .ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: var(--primary-15) !important;
}
