.tagsWrapper {
  display: flex;
  gap: 8px;
  padding: 16px 0 24px 0;
  flex-wrap: wrap;
}

.tagsInner {
  padding: 6px 12px;
  border: 1px solid #8E8E8E;
  border-radius: 4px;
}

.subjectSelectWrapper {
  padding: 8px 0 24px 0;
}

.editorWrapper {
  height: 300px;
}

.editorWrapper > div {
  height: 100%;
}
.editorCount {
  margin-top: 16px;
  text-align: right;
}

.error {
  color: var(--error);
}

