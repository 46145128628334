:root {
  --primary-50: #46776F;
  --primary-40: #4B8A80;
  --primary-30: #89BCB7;
  --primary-25: #B1CAC6;
  --primary-20: #BEDAD7;
  --primary-10: #DFEDEB;
  --primary-15: #D1DEDC;
  --primary-5: #EFF6F5;
  --primary-0: #F8F8F8;
  --secondary-50: #1F1F1F;
  --secondary-40: #545454;
  --secondary-30: #8E8E8E;
  --secondary-20: #E0E0E0;
  --secondary-10: #F2F2F2;
  --secondary-7: #F9F9F9;
  --secondary-5: #3D3D3D;
  --secondary-0: #FFFFFF;
  --shadow: #F0F0F0;
  --tertiary-1: #FF9C6E;
  --tertiary-2: #FFF2E8;
  --tertiary-3: #FFF0F6;
  --tertiary-4: #EB2F96;
  --error: #CF1322;
}

