.nameLabel {
  margin-bottom: 8px;
}

.surNameLabel {
  margin: 16px 0 8px;
}

.errorText {
  color: #ff4d4f;
  font-size: 12px;
}
