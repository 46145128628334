.singleGroupContainer {
  height: calc(100vh - 64px);
}

.scrollView {
    box-shadow: 0 4px 12px 0 #0000001F;
    background-color: var(--secondary-0);
    z-index: 1;
    position: relative;
}

.breadcrumbWrapper {
  padding: 28px 22px 0;
}

.titleWrapper {
  padding: 16px 24px 24px;
}

.descWrapper {
  padding: 0 24px 0 24px;
}

.scrollWrapper {
  height: calc(100vh - 220px);
  overflow-y: auto;
}

.createButtons {
  display: flex;
  gap: 8px;
}

.collapseWrapper {
    margin: 16px 0;
    background-color: var(--primary-10);
    border-radius: 20px;
    border: none;
}

.descCollapse {
    background-color: var(--secondary-0);
}

.collapseWrapper :global .ant-collapse-header {
    border-radius: 20px !important;
}
.collapseWrapper :global .ant-collapse-item:last-child {
    border-radius: 20px;
}

.collapseWrapper :global .ant-collapse-content {
    border-radius: 0 0 20px 20px !important;
}

.collapseWrapper :global .ant-collapse-item {
    border: none;
}

.collapseWrapper :global .ant-collapse-content-box {
    padding: 0 16px !important;
}

.collapseWrapper :global .ant-list-empty-text {
    padding: 0;
    text-align: left;
}

.typWrapper {
  display: flex;
  gap: 4px;
  align-items: center;
  white-space: nowrap;
}

.descInner {
    display: flex;
    gap: 8px;
    padding: 8px 0 16px;
    margin-left: 24px;
}