.radio :global .ant-radio-checked:not(.ant-radio-disabled) .ant-radio-inner::after {
  background-color: var(--primary-40);
  transform: scale(0.5);
}

.radio :global .ant-radio-disabled.ant-radio-inner::after {
  background-color: var(--secondary-30) !important;
  transform: scale(0.5);
}

.radio :global .ant-radio-checked .ant-radio-inner {
  background-color: transparent;
}

.radio :global .ant-radio-inner {
  border: 2px solid var(--secondary-40);
}
