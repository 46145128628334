.schoolFiltersWrapper {
    padding: 0 24px;
    margin: 32px 0 24px 0;
}

.schoolTableWrapper {
  padding: 0 24px;
}
.dropdownContent :global .ant-dropdown-menu-item {
    width: 100%;
}

.dropdownContent :global .ant-dropdown-menu-item {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.dropdownContent :global .ant-dropdown-menu-title-content > span {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.dropdownContent :global .ant-dropdown-menu-item:hover {
    background-color: var(--primary-5) !important;
}

.dropdownContent :global .ant-dropdown-menu-item > span > span {
    width: calc(100% - 32px);
    display: block;
}

.schoolsWrapper {
  height: calc(100vh - 64px);
  overflow-y: auto;
}

.paginationWrapper {
  padding: 0 24px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
}

.activeChips {
  padding: 2px 12px;
  color: var(--primary-40);
  border-radius: 20px;
  font-weight: bold;
  margin: 0 2px;
  background-color: var(--primary-10);
}

.deActiveChips {
    padding: 2px 12px;
    color: var(--secondary-40);
    border-radius: 20px;
    font-weight: bold;
    margin: 0 2px;
    background-color: var(--secondary-20);
}
