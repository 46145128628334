.subjectContainer {
  height: calc(100vh - 64px);
}

.scrollView {
  box-shadow: 0 4px 12px 0 #0000001F;
  background-color: var(--secondary-0);
  z-index: 1;
  position: relative;
}

.singleSubjectInner {
  height: calc(100vh - 220px);
  overflow-y: auto;
}

.breadcrumbWrapper {
  padding: 28px 22px 0;
}

.titleWrapper {
  padding: 16px 24px 24px;
}

.descWrapper {
  padding: 0 24px 0 24px;
}

.typWrapper {
  display: flex;
  gap: 4px;
  align-items: center;
  white-space: nowrap;
}

.descInner {
  display: flex;
  gap: 8px;
  padding: 8px 0 16px;
  margin-left: 24px;
}

.searchWrapper {
  padding: 0 4px;
  margin-bottom: 20px;
}

.subjectContent {
  padding: 24px 24px;
}

.filtersWrapper {
  display: flex;
  gap: 24px;
  align-items: center;
}

.itemCount {
  white-space: nowrap;
}

.itemsWrapper {
  padding: 8px 0;
}

.collapseWrapper {
  margin: 16px 0;
  background-color: var(--primary-10);
  border-radius: 20px;
  border: none;
}

.collapseDisabled {
  opacity: 0.7;
}

.descCollapse {
  background-color: var(--secondary-0);
}

.collapseWrapper :global .ant-collapse-header {
  border-radius: 20px !important;
}
.collapseWrapper :global .ant-collapse-item:last-child {
  border-radius: 20px;
}

.collapseWrapper :global .ant-collapse-content {
  border-radius: 0 0 20px 20px !important;
}

.collapseWrapper :global .ant-collapse-item {
  border: none;
}

.collapseWrapper :global .ant-collapse-content-box {
  padding: 0 16px !important;
}

.collapseWrapper :global .ant-list-empty-text {
  padding: 0;
  text-align: left;
}

.manageIcons {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 18px;
  padding: 14px;
  cursor: pointer;
}

.subItemWrapper {
  display: block !important;
  padding: 12px 0 !important;
}

.subItemInner {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dragHandler {
  width: 24px;
}

.subItemRight {
  flex: 1;
}

.subItemTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.subInfoWrapper {
  display: flex;
  gap: 16px;
}

.subInfoItem {
  display: flex;
  gap: 4px;
}

.disabled {
  pointer-events: none;
  user-select: none;
}

.disabled:hover {
  cursor: not-allowed;
}

.paginationWrapper {
  padding: 0 24px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
}

.dropdownContent :global .ant-dropdown-menu-item {
  width: 100%;
}

.dropdownContent :global .ant-dropdown-menu-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dropdownContent :global .ant-dropdown-menu-title-content > span {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.dropdownContent :global .ant-dropdown-menu-item:hover {
  background-color: var(--primary-5) !important;
}

.dropdownContent :global .ant-dropdown-menu-item > span > span {
  width: calc(100% - 32px);
  display: block;
}

