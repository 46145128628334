.breadCrumbWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.editorWrapper {
    margin-top: 24px;
    height: 400px;
}

.editorWrapper > div {
    height: 100%;
}

.customEditor > div:last-child {
    height: calc( 100% - 54px );
}

.editorCount {
    margin-top: 16px;
    text-align: right;
}

.error {
    color: var(--error);
}