.modal :global .ant-modal-content {
  padding: 0;
}

.modal :global .ant-modal-content {
  padding: 0;
}

.modal :global .ant-modal-close {
  top: 10px;
  inset-inline-end: 10px;
}

.modal :global .ant-modal-header {
  padding: 16px 24px;
  box-shadow: inset 0 -1px 0 var(--shadow);
  margin: 0;
}

.modal :global .ant-modal-body {
  padding: 24px;
  background-color: var(--primary-0);
}

.confirm :global .ant-modal-body {
 border-radius: 20px 20px 0 0;
}

.modal :global .ant-modal-footer {
  padding: 10px 16px;
  margin: 0;
  box-shadow: inset 0 1px 0 #f0f0f0;
  background-color: var(--secondary-0);
  border-radius: 0 0 20px 20px;
}

.okButton {
  background-color: var(--primary-40);
  font-weight: bold;
  box-shadow: none;
}

.cancelButton {
  color: var(--primary-40) !important;
  border-color: var(--secondary-40) !important;
  font-weight: bold;
}

.cancelButton:hover {
  color: var(--primary-50) !important;
  border-color: var(--secondary-40) !important;
}

.okButton:disabled:hover {
  background-color: initial !important;
}

.okButton:hover {
  background-color: var(--primary-50) !important;
}

.confirmIconWrapper {
  display: flex;
  gap: 8px;
  overflow-wrap: anywhere;
}

.confirmIcon {
  margin-top: 4px;
}

.fixedScroll :global .ant-modal-body {
  max-height: 500px;
  overflow-y: auto;
}
