.practiceWrapper {
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  margin: 16px 0;
}

.practiceItem {
  margin-bottom: 8px;
}

.paginationWrapper {
  padding: 0 24px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
}
