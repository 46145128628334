.collapseWrapper {
    margin: 16px 0;
    background-color: transparent;
    border-radius: 20px;
    border: none;
}

.titleWrapper {
    display: flex;
    justify-content: space-between;
}

.collapseWrapper :global .ant-collapse-header {
    border-radius: 20px !important;
    padding: 24px 22px !important;
}

.innerCollapse :global .ant-collapse-header {
    padding: 16px 22px !important;
}

.collapseWrapper :global .ant-collapse-item {
    border-radius: 20px;
    background-color: var(--primary-10);
    margin: 12px 0;
    box-shadow: 0 4px 8px 0 #00000033;
}

.collapseWrapper :global .ant-collapse-item:last-child {
    border-radius: 20px;
}

.collapseWrapper :global .ant-collapse-content {
    border-radius: 0 0 20px 20px !important;
}

.collapseWrapper :global .ant-collapse-item {
    border: none;
}

.collapseWrapper :global .ant-collapse-content-box {
    padding: 1px 0 !important;
}

.collapseWrapper :global .ant-list-empty-text {
    padding: 0;
    text-align: left;
}

.innerCollapse :global .ant-collapse-content-box {
    padding: 0  !important;
}

.innerCollapse :global .ant-collapse-item {
    background-color: var(--secondary-0) !important;
    margin: 0 !important;
    box-shadow: none;
}

.itemInfo {
    display: flex;
    gap: 16px;
    margin-top: 4px;
}

.infoInnerItem {
    display: flex;
    gap: 4px;
    align-items: center;
}

.subItemWrapper {
    padding: 12px 20px 12px 44px;
}

.subItemWrapper > li {
    padding: 12px 0 !important;
}

.paginationWrapper {
  padding: 0 24px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
}

.grantAccessWrapper {
  background-color: var(--secondary-0);
  border-radius: 20px;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--primary-30);
}

.accessTitleWrapper {
  margin-top: 8px;
}

.deactivateAccess {
  font-style: italic;
  display: flex;
  gap: 4px;
  justify-content: right;
  margin-bottom: 8px;
}

.resendLink {
  cursor: pointer;
}

.titleFirst {
  display: flex;
  gap: 8px;
  align-items: center;
}