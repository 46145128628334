.linksWrapper > div > a {
  color: var(--secondary-50);
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.linksWrapper > div > a:hover {
  color: var(--primary-40);
  font-weight: bold;
}

.linksWrapper {
  display: flex;
  gap: 16px;
}