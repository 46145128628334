.container {
    max-width: 1000px;
    margin: auto;
    font-family: Roboto, sans-serif;
    line-height: 1.2;
    padding: 60px;
    font-size: 14px;
}

.container :global .ant-col {
  font-size: 14px !important;
  font-family: Roboto, sans-serif !important;
  line-height: 1.2 !important;
}

.mb8 {
    margin-bottom: 8px;
}

.mb12 {
    margin-bottom: 12px;
}

.mb16 {
    margin-bottom: 16px;
}

.mb20 {
    margin-bottom: 20px;
}