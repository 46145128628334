.headWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nameWrapper {
  padding: 16px 0;
}

.evalWrapper {
    padding: 0 140px;
}

.evalInner {
    padding: 0 160px;
    height: calc(100vh - 139px);
    overflow-y: auto;
}

.statusWrapper {
    display: flex;
    gap: 8px;
    margin-bottom: 30px;
}

.collapseWrapper {
    margin: 16px 0;
    background-color: transparent;
    border-radius: 20px;
    border: none;
}

.collapseOutline {
    border: 1px solid var(--tertiary-4) !important;
}

.collapseWrapper :global .ant-collapse-header {
    border-radius: 20px !important;
    padding: 24px 22px !important;
}

.collapseWrapper :global .ant-collapse-item {
    border-radius: 20px;
    background-color: var(--primary-10);
    margin: 12px 0;
    box-shadow: 0 4px 8px 0 #00000033;
}

.collapseWrapper :global .ant-collapse-item:last-child {
    border-radius: 20px;
}

.collapseWrapper :global .ant-collapse-content {
    border-radius: 0 0 20px 20px !important;
}

.collapseWrapper :global .ant-collapse-item {
    border: none;
}

.collapseWrapper :global .ant-collapse-content-box {
    padding: 1px 0 !important;
}

.collapseWrapper :global .ant-list-empty-text {
    padding: 0;
    text-align: left;
}

.innerCollapse :global .ant-collapse-content-box {
    padding: 0  !important;
}

.innerCollapse :global .ant-collapse-item {
    background-color: var(--secondary-0) !important;
    margin: 0 !important;
    box-shadow: none;
}

.notFilledCollapse :global .ant-collapse-header {
    background-color: var(--tertiary-3);
    border-radius: 0 !important;
}

.notFilledCollapse :global .ant-collapse-header:last-child {
    background-color: var(--tertiary-3);
}

.innerCollapse :global .ant-collapse-item:last-child :global .ant-collapse-header {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

.innerCollapse :global .ant-collapse-item-active:last-child :global .ant-collapse-header {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.innerCollapse :global .ant-collapse-item-active:last-child :global .ant-collapse-header {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.innerCollapse :global .ant-collapse-item-active:last-child :global .ant-collapse-content-active :global .ant-collapse-content-box :global .ant-row:last-child {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

.innerCollapse :global .ant-collapse-header {
    padding: 15px 30px !important;
}

.radioGroup :global .ant-radio-wrapper {
    width: 25%;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
}

.openReviewBottomBorder {
    padding: 17px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.notFilledSubItem {
    background-color: var(--tertiary-3);
}
.paginationWrapper {
    padding: 0 24px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 8px;
}

.footer {
  display: flex;
  padding: 12px 0;
  gap: 16px;
  justify-content: center;
}

.footer > div > a {
    color: var(--secondary-50);
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
}

.footer > div > a:hover {
    color: var(--primary-40);
    font-weight: bold;
}
