.heading {
  font-family: 'Roboto', sans-serif;
  margin: 0;
}

.bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.level-1 {
  font-size: 32px;
}

.level-3 {
  font-size: 24px;
}

.level-4 {
  font-size: 18px;
}

.level-5 {
  font-size: 16px;
  line-height: 24px;
}

.text-1 {
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}

.text-2 {
  font-size: 16px;
  line-height: 1.2;
  margin: 0;
}

.text-3 {
  font-size: 12px;
  line-height: 1.2;
  margin: 0;
}
