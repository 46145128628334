.reportContainer {
    height: calc(100vh - 63px);
    position: relative;
}

.wrapper {
  height: calc(100% - 80px);
}

.wrapperInner {
    padding-bottom: 0;
}

.wrapperScrolled {
    box-shadow: 0 4px 12px 0 #0000001F;
    background-color: var(--secondary-0);
    z-index: 1;
    position: relative;
}

.breadcrumbWrapper {
    padding: 20px 22px 12px;
}

.titleWrapper {
    padding: 0 24px;
    height: calc(100% - 143px);
    overflow-y: auto;
}

.subTitleWrapper {
    padding: 0 24px;
}


.personalReview {
    padding: 0 24px;
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
    margin-top: 36px;
}

.personalReview > span {
    transform: rotate(180deg);
}

.collapseWrapper {
    margin: 16px 0;
    background-color: transparent;
    border-radius: 20px;
    border: none;
}

.descCollapse {
    background-color: var(--secondary-0);
}

.collapseWrapper :global .ant-collapse-header {
    border-radius: 20px !important;
}

.collapseWrapper :global .ant-collapse-item {
    border-radius: 20px;
    background-color: var(--primary-10);
    margin: 12px 0;
    box-shadow: 0 4px 8px 0 #00000033;
}

.collapseWrapper :global .ant-collapse-item:last-child {
    border-radius: 20px;
}

.collapseWrapper :global .ant-collapse-content {
    border-radius: 0 0 20px 20px !important;
}

.collapseWrapper :global .ant-collapse-item {
    border: none;
}

.collapseWrapper :global .ant-collapse-content-box {
    padding: 1px 0 !important;
}

.collapseWrapper :global .ant-list-empty-text {
    padding: 0;
    text-align: left;
}

.innerCollapse :global .ant-collapse-content-box {
    padding: 0  !important;
}

.innerCollapse :global .ant-collapse-item {
    background-color: var(--secondary-0) !important;
    margin: 0 !important;
    box-shadow: none;
}

.notFilledCollapse :global .ant-collapse-header {
    background-color: var(--tertiary-2);
    border-radius: 0 !important;
}

.notFilledCollapse :global .ant-collapse-header:last-child {
    background-color: var(--tertiary-3);
}

.innerCollapse :global .ant-collapse-item:last-child :global .ant-collapse-header {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

.innerCollapse :global .ant-collapse-item-active:last-child :global .ant-collapse-header {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.innerCollapse :global .ant-collapse-item-active:last-child :global .ant-collapse-header {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.innerCollapse :global .ant-collapse-item-active:last-child :global .ant-collapse-content-active :global .ant-collapse-content-box :global .ant-row:last-child {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

.innerCollapse :global .ant-collapse-header {
    padding: 12px 30px !important;
}

.radioGroup :global .ant-radio-wrapper {
    width: 25%;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
}

.collapseOutline {
    border: 1px solid var(--tertiary-1) !important;
}

.openReviewBottomBorder {
    padding: 17px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.openReviewBottomBorder:last-child {
}

.notFilledSubItem {
    background-color: var(--tertiary-2);
}

.openReviewMargin {
    margin-top: 8px;
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
}

.openReviewMargin > span {
    transform: rotate(180deg);
}

.footerSubmit {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--secondary-0);
  padding: 24px 36px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.paginationWrapper {
    padding: 0 24px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 8px;
}