.openReviewMargin {
    margin-top: 8px;
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
}

.titleWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
}