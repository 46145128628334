.headerWrapper {
    border-radius: 20px 20px 0 0;
    padding: 16px;
    background-color: var(--primary-10);
}

.collapseWrapper {
    border-top: 1px solid var(--secondary-20);
}

.collapseWrapper :global .ant-collapse-content-box {
  padding: 0 !important;
}

.collapseWrapper :global .ant-collapse-header {
  padding: 16px !important;
}

.infoWrapper {
    background-color: var(--secondary-0);
    padding: 16px;
    border-top: 1px solid var(--secondary-20);
    border-radius: 0 0 20px 20px;
    display: flex;
    flex-wrap: wrap;
}

.infoWrapper :global .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4b8a80;
    border-color: #4b8a80;
}

.infoWrapper :global .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: white !important;
}

.checkboxWrapper {
    margin: 8px 12px;
}

.secondBlock {
    margin-top: 24px;
    box-shadow: 0 4px 8px 0 #00000033;
    border-radius: 20px;
}

.yearsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inactiveWrapper {
  padding: 13px 16px;
  border-top: 1px solid var(--secondary-20);
}

.inactiveWrapper :global .ant-btn {
  padding: 0 12px;
  height: 28px;
}

.statusHeader {
  background-color: var(--secondary-10);
  border-top: 1px solid var(--secondary-20);
  padding: 6px 16px;
}

.actionWrapper {
  border-top: 1px solid var(--secondary-20);
  padding: 16px;
  align-items: center;
  height: 64px;
}

.dropdownContent :global .ant-dropdown-menu-item {
    width: 100%;
}

.dropdownContent :global .ant-dropdown-menu-item {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.dropdownContent :global .ant-dropdown-menu-title-content > span {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.dropdownContent :global .ant-dropdown-menu-item:hover {
    background-color: var(--primary-5) !important;
}

.dropdownContent :global .ant-dropdown-menu-item > span > span {
    width: calc(100% - 32px);
    display: block;
}