.itemLabel {
  margin-bottom: 8px;
}

.groupLabel {
  margin: 16px 0 8px;
}

.alisLabel {
  margin: 16px 0 8px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.itemTypeLabel {
  margin: 16px 0;
}

.errorText {
  color: #ff4d4f;
  font-size: 12px;
}