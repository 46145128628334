.observationsWrapper {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.breadCrumbWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.evalTable :global .ant-table-thead .ant-table-cell {
  text-align: center;
}

.evalTable :global .ant-table-cell {
  text-align: center;
}

.radio :global .ant-radio-checked .ant-radio-inner::after {
  background-color: var(--primary-40);
  transform: scale(0.5);
}

.radio :global .ant-radio-checked .ant-radio-inner {
  background-color: transparent;
}

.radio :global .ant-radio-inner {
  border: 2px solid var(--secondary-30);
}