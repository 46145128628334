.groupLabel {
  margin-bottom: 8px;
}

.descLabel {
  margin: 16px 0 8px;
}

.groupSelectLabel {
  margin: 16px 0 8px;
}

.schoolYearsLabel {
  margin: 16px 0 8px;
}

.errorText {
  color: #ff4d4f;
  font-size: 12px;
}